import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import s from "./FirstHeader.module.scss";
import LanguageSelector from "./LanguageSelector";

const FirstHeader = () => {
  const { t } = useTranslation();

  return (
    <div className={s.header}>
      <div className={s.container}>
        <div className={s.space} />

        <div className={s.headerContent}>
          <div style={{display : "flex" , gap : "40px"}}>
            <div>
            <p className={s.discount}>
              <span>{t("firstHeader.saleMessage")}</span>
              
            </p>
            </div>
            

            <div>
            <span>{t("firstHeader.shopNow")}</span>
            </div>
          </div>
          

          <LanguageSelector />
        </div>
      </div>
    </div>
  );
};

export default FirstHeader;
